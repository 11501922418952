<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&expand=keTang"
    entity-name="商品类别"
    add-btn="新增类别"
    hide-show-detail
    hide-delete
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="{ name: 1 }"
    :modal-width="440"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="商品类别名称">
        <a-input v-model="form.name" placeholder="输入商品类别名称查询" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="商品类别" prop="name">
        <a-input v-model="form.name" placeholder="请输入商品类别（最多10字）" :maxLength="10" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否对外开放">
        <a-switch v-model="form.is_open"></a-switch>
        <div class="tip-msg">
          勾选“是”，该类别下的所有商品将在{{
            form.id && form.system_fixed == 1 ? "【借用】" : "【申购】【领用】"
          }}中可选
        </div>
      </a-form-model-item>
      <a-form-model-item label="授权范围">
        <a-select
          mode="multiple"
          placeholder="选择堂口"
          :options="tkOptions"
          v-model="form.tangkou_ids"
          allowClear
          show-search
          option-filter-prop="children"
        >
        </a-select>
      </a-form-model-item>
    </template>

    <template v-slot:action="{ item }">
      <a class="txt-btn danger" @click.stop="delRow(item, index)" v-action:delete v-if="!item.system_fixed">删除</a>
    </template>
  </data-list>
</template>

<script>
import { clone, dataConvertOptions } from "../../common/js/tool";
export default {
  name: "CommodityCategory",
  data() {
    return {
      url: "/admin/asset-goods-category",
      columns: [
        { title: "商品类别", dataIndex: "name" },
        {
          title: "对外开放",
          customRender: (record) => {
            return (
              <a-switch checked={record.is_open == 1} onChange={($event) => this.onChange($event, record)}></a-switch>
            );
          },
        },
        {
          title: "授权范围",
          dataIndex: "keTang",
          customRender: (text) => {
            return text?.map((item) => <a-tag>{item.name}</a-tag>);
          },
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入商品类别", trigger: "blur" }],
      },
      tkOptions: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    onChange(checked, record) {
      let text = checked ? "开启" : "关闭";
      this.$confirm({
        title: "提示",
        content: `确定${text}此商品类型的对外开放状态吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${record.id}`,
            method: "PATCH",
            data: {
              is_open: checked ? 1 : 2,
            },
          }).then((res) => {
            record.is_open = res.is_open;
            this.$message.success(text + "成功");
          });
        },
      });
    },
    beforeEdit(form, fn) {
      form.is_open = form.is_open == 1; // 1: 是 2: 否
      if (form.tangkou_ids) {
        form.tangkou_ids = form.tangkou_ids.split(",")?.map(id => Number(id));
      } else {
        form.tangkou_ids = [];
      }
      fn(form);
    },
    beforeUpdate(form, fn) {
      let res = clone(form);
      res.is_open = res.is_open ? 1 : 2; // 1: 是 2: 否
      if (res.tangkou_ids) {
        res.tangkou_ids = res.tangkou_ids.join(",");
      }
      fn(res);
    },
    delRow(item, index) {
      this.$refs.dataList.delRow(item, index);
    },
  },
};
</script>

<style lang="less" scoped>
.tip-msg {
  color: grey;
}
</style>
